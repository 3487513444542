.table-cultures-container{
    padding: 20px;
    margin-top: 120px
}

.commands{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.container{
    margin: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
}