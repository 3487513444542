.table-container{
    padding: 20px;
}

.empty{
    padding: 30px;
    text-align: center;
    font-size: 20px;
}

.container{
    padding: 20px;
    display: flex;
    justify-content: center;
}