.table-yieldRegions-container{
    padding: 10px;
    margin-top: 120px;
    transition: all 0.5s;
}

.no-results{
    padding:20px;
    font-size: 30px;
    text-align: center;
    color:#888;


}

.no-results img{
    opacity: 0.5;
    max-height: 200px;
        
}