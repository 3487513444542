body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::before{
  content: "";
  left:-20px;
  top:-20px;
  right: -20px;
  position: fixed;
  height: 50px;
  background-color: #1b5e20;
  z-index: 1000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-control{
    transform: scale(1.5);
    margin-top: 30px !important;


}

.leaflet-right {
  display: none;
}

.dialog-bottom .MuiDialog-paper{
  position: absolute;
  bottom: 0px !important;
  min-width: 80%;
  max-width: 100%;

}

.d-title{
  font-size: 16px;
}


@media only screen and (max-width: 750px) {
.d-title{
  font-size: 14px;
}

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}