.avatar{
    width: 80px;
    height: 80px;
    margin:auto;
    border-radius: 50%;
}

.avatar-header{
    padding: 10px;
    text-align: center;
    min-width: 250px;
}

.list{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.logo-header img{
    height: 50px;
}


@media only screen and (max-width: 750px) {
    .logo-header img{
        height: 40px;
    }
}