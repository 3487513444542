#geo-map{
    position: relative;
    max-width: 650px;
}

#controls {
    position: relative;
    z-index: 200;
}

#canvas {
    position: relative;
    max-width: 100%;
    z-index: 100;
}

#canvas {
    cursor: grab;
  }
  
  #canvas:active {
    cursor: grabbing;
  }

#tooltip{
    position: absolute;
    display: none;
    background: #262626;
    border-radius: 7.5px;
    color: #ccc;
    font-family: Arial;
    font-size: 16px;
    padding: 10px 15px;
    border: 2px solid #ccc;
    z-index: 1000;
}

.tooltitle{
    color: #888;
}

.AxisLegend{
    position: absolute;
    top: 0;
    right: 35px;
    bottom: 0;
    width: 200px;
    height: 100%;
    transform: rotate(90deg) translateY(160px);
}

.AxisDamageLegend{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 35px;
    text-align: center;
    z-index: 1000;
    
}