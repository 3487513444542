.disabled{
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.commands{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}