.form-container{
    min-width: 300px;
    max-width: 100%;
}

.recover-container{
    padding: 10px;
    color: #666;
}

.button-container{
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.logo{
    text-align: center;
}

.logo img{
    max-width: 100%;
    max-height: 250px;
}