.table-yieldRegions-container{
    padding: 10px;
    margin-top: 140px;
    transition: all 0.5s;
}

.rightAlign{
    text-align: right;
}

.milliers{
    font-size: 10px;
    color: #ccc;
}

.legendOptions{
    margin: auto;
    width: 450px;
}

.regions-viewport{
    padding-top: 20px;
    padding-bottom: 20px;
}

.no-results{
    padding:20px;
    font-size: 30px;
    text-align: center;
    color:#888;


}

.no-results img{
    opacity: 0.5;
    max-height: 200px;
        
}