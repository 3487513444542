body {
   background: #dedede;
}

body.green {
   background: #1b5e20;
}


.main-content{
   margin-top: 60px;
   height: calc(100vh - 60px);
   width: 100vw;
   background-color: #dedede;
}

.fullscreen{
   position: fixed;
   height: calc(100vh - 60px);
   width: 100vw;
   top:60px;
   overflow: hidden;
}

.cordova .fullscreen{
   height: calc(100vh - 100px);
   width: 100vw;
   top:100px;
}


.fullscreen-withbar{
   position: fixed;
   height: calc(100vh - 120px);
   width: 100vw;
   top:120px;
}

.cordova .fullscreen-withbar{
   height: calc(100vh - 160px);
   top:160px;
}



.full-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.full-item{
  flex: 1;
}

.regular-dialog .MuiDialog-paper{
   min-width: 360px;
}

@media only screen and (max-width: 750px) {
    .main-content{
      margin-top: 50px;
      height: calc(100vh - 50px);
      width: 100vw;
      overflow: scroll;
   }

   .cordova .main-content{
      margin-top: 90px;
      height: calc(100vh - 90px);
   }

   .fullscreen-withbar{
      height: calc(100vh - 100px);
      top:100px;
   }

   .cordova .fullscreen-withbar{
      height: calc(100vh - 140px);
      top:140px;
   }

}
