.search-box{
    background: #fff;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    min-width: 50%;
}

.legend-clima{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    color: blue;
}

.line{
    margin: 4px;
    margin-top: 5px;
    border-bottom: 2px solid blue;
}

.line.dashed{
    border-style: dashed;
    border-bottom-width: 0.3px;

}

.icon_line img{
    max-width: 100%;
    max-height: 8px;
}

.parcelle-info{
    position: absolute;
    top: 136px;
    left: 50px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
}

.cordova .parcelle-info{
    top: 176px;
}


.parcelle-info .MuiPaper-elevation1{
    background: linear-gradient(111deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 37%, rgba(255,255,255,0.6026785714285714) 100%);
}


.flex{
    flex:1;
    justify-content: center;
}

.flex-gap{
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    align-items: center;
    gap: 10px;
}


.selector{
    background-color: #fff;
    margin-left: 10px !important;
    margin-right: 10px !important;
    border-radius: 10px !important;
    padding-left: 20px  !important;
    padding-right: 20px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;

}


.legend{
    width: 50px;
    height: 20px;
    text-align: center;
    padding-top: 4px;
}

.table-ndvi{
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.l005{
    background-color: rgb(140, 0, 0);
}

.l03{
    background-color: rgb(255, 0, 0);
}

.l04{
    background-color: rgb(255, 125, 0);
}

.l05{
    background-color: rgb(255, 255, 0);
}

.l06{
    background-color: rgb(125, 255, 0);
}

.l07{
    background-color: rgb(0, 255, 0);
}

.l08{
    background-color: rgb(0, 180, 0);
}

.l09{
    background-color: rgb(0, 80, 0);
}

.info p{
    margin-top: 5px;
    margin-bottom: 5px;
}

.header-container{
    display: flex;
    align-items: center;
    justify-content: center
}

.subheader{
      background-color: #8cc981 !important;
      top: 60px !important;

}

.cordova .subheader{
      top: 100px !important;

}

.anim{
    transition: all 0.5s;
}

.expanded{
    transition: all 0.5s;
    max-height: 1000px;
}

.collapsed{
    transition: all 0.5s;
    opacity:0;
    max-height: 0;
}

.composed-chart{
    margin-top: 20px;
}

.yieldInfo{
    margin-top: 20px;
    margin-bottom: 10px;
    max-width: 400px;
}

.report-buttons{
    position: absolute;
    text-align: right;
    width: 90%;
    padding-right: 40px;
    flex-direction: column;
}

.card-content{
    min-width: 450px;
}

@media only screen and (max-width: 750px) {
    .subheader{
      top: 50px !important;
    }
    .cordova .subheader{
      top: 90px !important;
    }
    .parcelle-info{
            left: 0px;

        transition: all 0.4s ease-in-out;

        transform: scale(.7) translateX(-17%) translateY(-27%);
    }

    .composed-chart{
        transform: scale(1.3) translateX(0%) translateY(0%);
    }

    .line{
         transform: scale(1.1);
    }

    .parcelle-info.col{
        transition: all 0.4s ease-out;
        transition-delay: .4s;
        transform: scale(.7) translateX(-17%) translateY(-37%);
    }

    .header-container button span{
        transform: scale(1.5);
    }
    .header-container time{
        font-size: 18px !important;

    }
    .parcelle-info button{
        font-size: 16px;
    }

    .table-ndvi{
        display: none;
    }

    .search-box{
        max-width: 400px;
    }

    .leaflet-control-zoom{
        display: none;
    }


    .card-content{
        min-width: 400px;
    }
}

.custom-tooltip{
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    font-size: 10px;
}

@media only screen and (max-width: 750px) and  (orientation: landscape) {

    .extra-info{
        display: none;
    }


    .search-box{
        max-width: 400px;
    }

}

@media only screen and (max-height: 850px)  {

    .extra-info{
        display: none;
    }
    .search-box{
        max-width: 400px;
    }

}


@media only screen and (min-width: 768px) and  (max-width: 1024px) {

    .search-box{
        max-width: 400px;
    }


    .parcelle-info{
        transform:  scale(0.8) translateX(-11%) translateY(-16%);
    }

    .line{
         transform: scale(1.2);
    }

    .composed-chart{
        transform: scale(1.2) translateX(0%) translateY(0%);
    }

    .header-container button span{
        transform: scale(1.5);
    }
    .header-container time{
        font-size: 18px !important;

    }
    .parcelle-info button{
        font-size: 16px;
    }
}

@media only screen and (max-height: 850px)  {


    .search-box{
        max-width: 400px;
    }


    .parcelle-info{
        transform:  scale(0.8) translateX(-11%) translateY(-12%);
    }

    .line{
         transform: scale(1.2);
    }
    .composed-chart{
        transform: scale(1.2) translateX(0%) translateY(0%);
    }

    .header-container button span{
        transform: scale(1.5);
    }
    .header-container time{
        font-size: 18px !important;

    }
    .parcelle-info button{
        font-size: 16px;
    }

}

.spinner{
    flex: 1;
    justify-content: center;
    display: flex;
    padding:10px;
}